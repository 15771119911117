import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    const date = new Date();

    return (
        <footer>
            <div className='copyright-wrapper'>
                <span>&copy; {date.getFullYear()}&nbsp;</span><span className='mona'>mona</span>&nbsp;<span className='light'>ARCHITEKTUR</span>
            </div>
            <div className='imprint-wrapper'>
                <span className='separator'>|</span>
                <Link className='imprint' to='/imprint'>Impressum & Datenschutz</Link>
            </div>
        </footer>
    )
}

export default Footer
