const NewsCard = ( {pieceData} ) => {
    const img = pieceData.picture
    const text = pieceData.text
    const headline = pieceData.headline
    const date = pieceData.date
    const picFormat = pieceData.pic_format
    
    return (
        <div className='news-card'>
            <div className='img-wrapper'>
                <img className={picFormat} src={img} alt='alt' />
            </div>
            <h2>{headline}</h2>
            <p className='description'>{date} - {text}</p>
        </div>
    )
}

export default NewsCard
