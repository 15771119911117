import teamimage from "../images/office/team.jpg";
import eu from "../images/office/eu_kofinanziert.png";
import landtag from "../images/office/landtag_mitfinanziert.png";

const Office = ({ office }) => {
  const about = office.about;
  const closing = office.about.closing;
  const services = office.services;
  const qualifications = office.qualifications;

  return (
    <div className="office">
      <div className="contact">
        <div>
          <h2 className="heading-contact">KONTAKT //</h2>
        </div>
        <span className="mona">mona</span>{" "}
        <span className="light">ARCHITEKTUR</span> <br />
        <span className="light">Dähne & Tröger Partnerschaft mbB</span> <br />
        Fritz-Reuter-Straße 15
        <br />
        01097 Dresden
        <br />
        <br />
        T +49 351 796 90 70
        <br />
        <br />
        <a href="mailto:mail@mona-architektur.de">mail@mona-architektur.de</a>
      </div>

      <div className="us">
        <div className="about">
          <div>
            <h2>{about.headline}</h2>
          </div>
          {about.content.map((singleContent, index) => (
            <div key={index} className="single-content">
              {singleContent}
            </div>
          ))}
          <div className="single-content">{closing}</div>
        </div>
        <div className="services">
          <div>
            <h2>{services.headline}</h2>
          </div>
          {services.content.map((singleContent, index) => (
            <div key={index} className="single-content">
              {singleContent}
            </div>
          ))}
        </div>
        <div className="qualifications">
          <div>
            <h2>{qualifications.headline}</h2>
          </div>
          {qualifications.content.map((singleContent, index) => (
            <div key={index} className="single-content">
              {singleContent}
            </div>
          ))}
        </div>
        <div className="financing">
          <img
            className="img-eu"
            src={eu}
            alt="Kofinanziert von der Europäischen Union"
          />
          <img
            className="img-landtag"
            src={landtag}
            alt="Diese Maßnahme wird mitfinanziert durch Steuermittel auf der Grundlage des vom
Sächsischen Landtag beschlossenen Haushaltes."
          />
        </div>
      </div>

      <div className="team">
        <img className="img-team" src={teamimage} alt="the architects" />
        <div>Architektinnen Claudia Dähne und Stefanie Tröger</div>
      </div>
    </div>
  );
};

export default Office;
