export const data = {
  news: [
    {
      id: 4,
      headline: "WTA // Büro",
      date: "03/22",
      picture: require("../images/news/news_wta.png"),
      pic_format: "square",
      text: "Gelistet. Nach bestandener Prüfung zum „Fachplaner für Bauwerksinstandsetzung nach WTA“ bei der Eipos GmbH sind wir nunmehr Fachfrauen für das Bauen im Bestand und als solche bei der Architektenkammer Sachsen gelistet.",
    },
    {
      id: 3,
      headline: "BIM // Büro",
      date: "12/21",
      picture: require("../images/news/news_bim.png"),
      pic_format: "square",
      text: "Zertifizierung. Wir sind nun nach bestandener Prüfung nach buildingSMART/VDI 2552 für BIM qualifiziert und verwenden diese Methode nun noch fundierter in unserer täglichen Arbeit.",
    },
    {
      id: 2,
      headline: "N17 // Projekte",
      date: "10/21",
      picture: require("../images/news/news_n17_2110_small.jpg"),
      pic_format: "square",
      text: "Abbruch. Die ersten Schichten sind freigelegt und wir sehen dem Baustart im Januar entgegen.",
    },
    {
      id: 1,
      headline: "FS8 // Projekte mal anders",
      date: "09/21",
      picture: require("../images/news/news_fs8_2109_small.jpg"),
      pic_format: "circle",
      text: "Anleiterversuch. Um die mögliche Personenrettung aus dem Dachgeschoss zu prüfen, bedurfte es hier des Hubrettungsgerätes vor Ort. Der direkte Austausch mit der Feuerwehr verändert den Blickwinkel.",
    },
    {
      id: 0,
      headline: "Neue Räume // Büro",
      date: "06/21",
      picture: require("../images/news/news_new_rooms.jpg"),
      pic_format: "square",
      text: "Einzug. Wir beziehen unsere neuen Büroräume in der Dresdner Neustadt! In Bürogemeinschaft mit zwei weiteren freien Architektinnen bieten wir nun von hier unsere Expertise in der täglichen Arbeit bei Neu- und Umbauten sowie Sanierungsprojekten an.",
    },
  ],
  projects: [
    {
      pic_1: require("../images/projects/projekte_2_1.png"),
      pic_2: require("../images/projects/projekte_2_2.png"),
      pic_3: require("../images/projects/projekte_2_3.png"),
      headline: "009_N17",
      subline: "// Umbau und Sanierung Villa",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.",
    },
    {
      pic_1: require("../images/projects/projekte_1_1.png"),
      pic_2: require("../images/projects/projekte_1_3.png"),
      pic_3: require("../images/projects/projekte_1_2.png"),
      headline: "008_FS8",
      subline: "// Umbau und Sanierung Mehrfamilienhaus",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.",
    },
  ],
  office: {
    about: {
      headline: "WIR //",
      content: [
        "// bauen für unsere Mitmenschen und achten in erster Linie auf deren Bedürfnisse und Wünsche",
        "// behandeln jede Bauaufgabe mit der ihr gebührenden Gründlichkeit und Individualität",
        "// verstehen das Bauen als gesellschaftliche Aufgabe, da es unser aller Umgebung gestaltet und langfristig prägt",
        "// besetzen mit jedem Bauwerk ein Stück Boden und werden es so tun, dass die verbleibenden Freiräume eine hohe Aufenthaltsqualität besitzen",
        "// streben immer wieder nach innovativen Ansätzen des Arbeitens, Wohnens, Lernen und Spielens",
        "// sind davon überzeugt, dass Architektur zu einer sozial, kulturell, ökologisch und ökonomisch nachhaltigen Zukunft beitragen kann",
        "// prüfen beim Arbeiten im Bestand stets die Sanierung und den Umbau vor einem Abriss und dem Neubau",
        "// versuchen bei jedem Projekt vor Ort vorhandene Baumaterialien zu nutzen und recyclingfähige Baumaterialien einzubauen",
        "// setzen auf zeitloses Design und wertiges, in Würde alterndes Material um unserem Anspruch auf Dauerhaftigkeit gerecht zu werden",
      ],
      closing:
        "Unseren ökologischen Ansatz versuchen wir auch im Arbeitsalltag umzusetzen. Wir bewältigen unsere Wege in der Stadt mit dem Rad, suchen auf Ecosia, nutzen ökologische Reinigungsmittel, trinken Leitungswasser und lassen unsere Büroräume mittels Erdwärmepumpe beheizen",
    },
    services: {
      headline: "UNSERE LEISTUNGEN //",
      content: [
        "// mit unserer langjährigen Berufserfahrung als Architektinnen stehen wir Ihnen mit allen klassischen Architektenleistungen in allen Leistungsphasen (LPH 1-8) zur Seite",
        "// wir unterstützen Sie bei Ihrem Bauvorhaben im Bereich Neubau, Umbau, Sanierung und Innenausbau",
        "// städtebauliche Studien gehören ebenfalls zu unserem Leistungsbild",
        "// mit unserer Expertise begleiten wir sowohl private als auch öffentliche Bauvorhaben",
      ],
    },
    qualifications: {
      headline: "UNSERE QUALIFIKATIONEN //",
      content: [
        "// wir sind nach buildingSMART/VDI 2552 für BIM qualifiziert",
        "// als Fachplaner für Bauwerksinstandsetzung nach WTA begleiten wir Sie mit einer integrativen Herangehensweise bei ganzheitlicher Bauwerkserhaltung und Bausanierung unter Berücksichtigung denkmalpflegerischer Belange sowie bauphysikalischer Zusammenhänge von der Instandsetzungskonzeption bis zur Ausführungsplanung",
        "// als qualifizierte Brandschutzplaner sind wir beratend tätig und können für alle Gebäudeklassen und Sonderbauten Brandschutzkonzepte entwickeln und Brandschutznachweise zur Bauvorlage erstellen",
        "// Die Weiterbildungen BIM nach buildingSMART/VDI 2552 und Fachplaner für Bauwerksinstandsetzung nach WTA wurden gefördert.",
      ],
    },
  },
};
