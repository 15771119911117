import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Start from './components/Start'
import Office from './components/Office'
import Projects from './components/Projects'
import Imprint from './components/Imprint'
import {data} from './data/data'
import { useState } from 'react'

import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Link } from 'react-router-dom'

const App = () => {

  const news = data.news
  const office = data.office
  const projects = data.projects
  const [showBurger, setShowBurger] = useState(false)
  
  return (
    <div className="container">
      <Router>
        <Header showBurger={showBurger} toggleBurger={() => setShowBurger(!showBurger)}/>
        <div className='content'>
          <Routes>
            <Route path='/' element={<Start news={news}/>}></Route>
            <Route path='projekte' element={<Projects projects={projects}/>}></Route>
            <Route path='buero' element={<Office office={office} />}></Route>
            <Route path = 'imprint' element={<Imprint />}></Route>
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  )
}

export default App
