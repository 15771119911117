import { Link } from 'react-router-dom'

const Burger = ({toggleBurger, showBurger}) => {

    return (
            <div className='burger' onClick={toggleBurger}>
                <div className={showBurger ? 'burger-bar active' : 'burger-bar'}></div>
                <div className={showBurger ? '' : 'burger-bar'}></div>
                <div className={showBurger ? 'burger-bar active' : 'burger-bar'}></div>
            </div>
    )
}

export default Burger