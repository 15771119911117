const Projects = ( {projects} ) => {
    return (
        <div className='projects'>
            {projects.map((project) => (
                <div className='project'>
                    {/* <div className='images'>
                        <img src={project.pic_1} />
                        <img src={project.pic_2} />
                        <img src={project.pic_3} />
                    </div>
                    <div className='description'>
                        <h2>{project.headline}</h2>
                        <h3>{project.subline}</h3>
                        <span>{project.text}</span>
                    </div> */}
                </div>
            ))}
            <div class='wip'>Work in Progress</div>
        </div>
    )
}

export default Projects