import NewsCard from './NewsCard'

const Start = ({news}) => {
    return (
        <div className='start'>
                {news.map((newsPiece) => (
                    <NewsCard key={newsPiece.id} pieceData={newsPiece} />
                ))}
        </div>
    )
}

export default Start
